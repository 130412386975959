import React from "react";
import { ChainProvider } from "../contexts/ChainContext";
import { ModalProvider } from "../contexts/ModalContext";
import { ChainConfigProvider } from "../contexts/ChainConfigContext";
import { PUBLIC_URL, WALLETCONNECT_PROJECT_ID } from "../config/constants";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import chainList from "../config/chainList";
import { ToastrProvider } from "../contexts/ToastrContext";
import { AuthenticationProvider } from "../contexts/AuthenticationContext";
import { BasicAuthProvider } from "../contexts/BasicAuthContext";
import { FormProvider } from "../contexts/FormContext";
import { TokenBalancesProvider } from "../contexts/TokenBalancesContext";

createWeb3Modal({
  ethersConfig: defaultConfig({ name: "Olympex" } as any),
  chains: chainList as any,
  projectId: WALLETCONNECT_PROJECT_ID,
  chainImages: {
    31337: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 31337)?.image}`,
    81457: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 81457)?.image}`,
    25: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 25)?.image}`,
    2222: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 2222)?.image}`,
    59144: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 59144)?.image}`,
    169: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 169)?.image}`,
    5000: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 5000)?.image}`,
    34443: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 34443)?.image}`,
    204: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 204)?.image}`,
    534352: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 534352)?.image}`,
    40: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 40)?.image}`,
    1101: `${PUBLIC_URL}/images/chains/${chainList.find((e) => e.chainId == 1101)?.image}`,
  },
});

export default function RootLayout({ children }) {
  return (
    // TODO: Join the ChainConfigProvider and ChainProvider
    <ToastrProvider>
      <ChainConfigProvider>
        <ChainProvider>
          <ModalProvider>
            <AuthenticationProvider>
              <FormProvider>
                <TokenBalancesProvider>
                  <BasicAuthProvider>
                    {children}
                  </BasicAuthProvider>
                </TokenBalancesProvider>
              </FormProvider>
            </AuthenticationProvider>
          </ModalProvider>
        </ChainProvider>
      </ChainConfigProvider>
    </ToastrProvider>
  );
}
