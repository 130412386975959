import React from "react";

const Input = React.forwardRef((props: any, ref) => {
  const { type = "text", className = "", ...rest } = props;

  return (
    <input
      ref={ref}
      type={type}
      className={`bg-transparent outline-none border-0 text-white w-full ${className}`}
      {...rest}
    />
  );
});

export default Input;
