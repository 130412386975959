import React, { useState } from "react";
import Modal from "../components/shared/Modal";

const ModalContext = React.createContext({
  openModal: (title: string, component: any) => undefined,
  closeModal: () => undefined,
});

const ModalProvider = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [title, setTitle] = useState<string | undefined>("");
  const [component, setComponent] = useState<any>();

  const openModal = (
    title: string = "",
    component: any = undefined,
  ): undefined => {
    setTitle(title);
    setComponent(component);
    setModalOpened(true);
  };

  const closeModal = (): undefined => {
    setModalOpened(false);
    setTitle(undefined);
    setComponent(undefined);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      {!!modalOpened && <Modal title={title}>{component}</Modal>}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
