import React from "react";

export default function NftsBackgroundShapes() {
  return (
    <>
      <div className="shape-1 animate-fade-up animate-duration-1500 animate-once" />
      <div className="shape-2 animate-fade-up animate-duration-1500 animate-once" />
      <div className="shape-3 animate-fade-up animate-duration-1500 animate-once" />
      <div className="shape-4 animate-fade-up animate-duration-1500 animate-once" />
    </>
  );
}
