import React, { useState } from "react";
import { AUTH_CODE } from "../config/constants";
import BasicAuthPage from "../components/BasicAuthPage";

const BasicAuthContext = React.createContext({
  code: "" as string,
  setCode: (val:string) => void 0 as any
});

const BasicAuthProvider = ({ children }) => {

  const [ code, setCode ] = useState(``);

  const authenticated = code === AUTH_CODE;
  
  return (
    <BasicAuthContext.Provider value={{
      code,
      setCode
    }}>
      {!authenticated
        ? <BasicAuthPage />
        : children
      }
    </BasicAuthContext.Provider>
  );
};

export { BasicAuthContext, BasicAuthProvider };