import fetch from "cross-fetch";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { GRAPHQL_URL } from "../config/constants";

export const client = new ApolloClient({
  link: new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
  }),
  cache: new InMemoryCache(),
});
