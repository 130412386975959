import React, { FC, useEffect, useState } from "react";
import Toastr from "../components/shared/Toastr";

interface INotification {
  text: any;
  variant: string;
}

const ToastrContext = React.createContext({
  notify: (text: any, variant: string) => undefined,
});

const ToastrProvider = ({ children }) => {
  const [notification, setNotification] = useState<INotification>();

  const notify = (text: any, variant: string = ""): undefined => {
    setNotification({
      text: text,
      variant: variant,
    });
  };

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        setNotification(undefined);
      }, 6000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  return (
    <ToastrContext.Provider
      value={{
        notify,
      }}
    >
      {children}
      {notification && (
        <Toastr
          close={() => setNotification(undefined)}
          text={notification.text}
          variant={notification.variant}
        />
      )}
    </ToastrContext.Provider>
  );
};

export { ToastrContext, ToastrProvider };
