export const getPercentile = (percentile: number, array: any[]) => {
  array.sort(function (a, b) {
    return a - b;
  });
  const index = (percentile / 100) * array.length;
  if (Math.floor(index) === index) {
    return (array[index - 1] + array[index]) / 2;
  } else {
    return array[Math.floor(index)];
  }
};
