export const priorityTokenTickers = [
    'USDT',
    'USDC',
    'DAI',
    'BTC',
    'WBTC',
    'ETH',
    'WETH',
    'BNB',
    'WBNB',
]