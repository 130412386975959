import React, { useEffect, useState } from "react";
import useWalletConnect from "../hooks/useWalletConnect";
import { DEFAULT_CHAIN_ID } from "../config/constants";

const ChainContext = React.createContext({
  selectedChainId: DEFAULT_CHAIN_ID,
  connectedAccount: undefined as any,
  open: (params = undefined as any) => void 0 as any,
  disconnect: () => void 0 as any,
  switchNetwork: async (chainId:number) => void 0 as any,
  walletProvider: undefined as any
});

const ChainProvider = ({ children }) => {

  const { address: account, chainId, open: _open, disconnect, switchNetwork, walletProvider } = useWalletConnect();
  const [ selectedChainId, setSelectedChainId ] = useState<number>(DEFAULT_CHAIN_ID);
  const [ connectedAccount, setConnectedAccount ] = useState<string | undefined>();
   
  const open = (props = undefined) => {
    try {
      _open(props);
    } catch (e) {
      _open(props);
    }
  }

  useEffect(() => {
    if (!!chainId) {
      setSelectedChainId(chainId);
    }
  }, [chainId]);

  useEffect(() => {
    setConnectedAccount(account);
  }, [account]);
        
  return (
    <ChainContext.Provider value={{ 
      selectedChainId,
      connectedAccount,
      open,
      disconnect,
      switchNetwork,
      walletProvider
    }}>
      {children}
    </ChainContext.Provider>
  );
};

export { ChainContext, ChainProvider };