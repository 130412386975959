import React, { FC, useContext, useEffect, useState } from "react";
import { ChainContext } from "./ChainContext";
import { ToastrContext } from "./ToastrContext";
import { useMutation } from "@apollo/client";
import { AUTHENTICATE } from "../config/queries";
import { ethers } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";

const AuthenticationContext = React.createContext({
  token: undefined as undefined | string,
  authenticate: () => void 0 as any,
  isAuthenticating: false,
});

const AuthenticationProvider = ({ children }) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { connectedAccount } = useContext(ChainContext);
  const { notify } = useContext(ToastrContext);

  const [_authenticate, { loading: isAuthenticating }] = useMutation(AUTHENTICATE);
  const [token, setToken] = useState<string | undefined>();

  const authenticate = async () => {
    if (connectedAccount && !!walletProvider) {
      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await provider.getSigner();
        const signature = await signer.signMessage(connectedAccount);
        const response = await _authenticate({
          variables: {
            auth: {
              publicKey: connectedAccount,
              signature: signature,
            },
          },
        });
        const newToken = response?.data?.authenticate?.token;
        if (newToken) {
          localStorage.setItem(`jwt_${connectedAccount}`, newToken);
          setToken(newToken);
        }
      } catch (err:any) {
        notify(err?.message || err, "error");
      }
    }
  };

  useEffect(() => {
    if (connectedAccount) {
      const storedToken = localStorage.getItem(`jwt_${connectedAccount}`);
      setToken(storedToken || undefined);
    } else {
      setToken(undefined);
    }
  }, [connectedAccount]);

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        authenticate,
        isAuthenticating,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationProvider };
