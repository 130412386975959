import React, { useContext, useEffect, useState } from 'react'
import { SCREEN_MAX_WIDTH } from '../config/constants'
import Card from './shared/Card'
import Input from './shared/Input'
import { BasicAuthContext } from '../contexts/BasicAuthContext'
import Button from './shared/Button'
import { ToastrContext } from '../contexts/ToastrContext'
import NftsBackgroundShapes from './NftsBackgroundShapes'
import { StaticImage } from 'gatsby-plugin-image'

export default function BasicAuthPage() {

  const { code, setCode } = useContext(BasicAuthContext); 
  const { notify } = useContext(ToastrContext);
  const [ value, setValue ] = useState(``);

  const submit = () => {
    const _value = value.trim();
    setCode(_value);
    localStorage.setItem(`code`, _value);
    setValue("");
  }

  useEffect(() => {
    if (!!code && !value.trim()) {
      notify('Incorrect code', 'danger');
    }
  }, [code, value]);

  useEffect(() => {
    setValue(
      typeof window !== `undefined` 
        ? (localStorage.getItem(`code`) || ``)
        : ``
    );
  }, []);

  return (
    <div className='layout'>
      <main className="flex-1 overflow-y-auto p-0 py-4 lg:pt-8 lg:p-8 lg:px-24 overflow-x-hidden">
        <div
          className="mx-auto w-full flex flex-col gap-12 items-center justify-center min-h-full"
          style={{ maxWidth: `${SCREEN_MAX_WIDTH}px` }}
        >
          <NftsBackgroundShapes />
          <StaticImage 
            src="../images/logo.png"
            width={200}
            alt="olympex logo"
          />
          <Card className='w-full max-w-[350px] text-center flex flex-col gap-4'>
            <p>Insert authentication code</p>
            <Card className='bg-black/20 !p-3'>
              <Input 
                placeholder="------"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && submit()}
                className="text-center text-2xl tracking-[8px]"
              />
            </Card>
            <Button
              variant='primary'
              disabled={!value}
              className='w-full text-center'
              onClick={submit}
            >
              Enter Olympex
            </Button>
          </Card>
        </div>
      </main>
    </div>
  )
}
