import React from "react";

export default function Card({ children, className = "" }) {
  return (
    <div
      className={`card p-4 lg:p-6 rounded-2xl lg:rounded-lg bg-[#373539] shadow-[0px,4px,4px,0px,#00000040] ${className}`}
    >
      {children}
    </div>
  );
}
