import { Chain } from "@usedapp/core"

const telosExplorerUrl = 'https://cronoscan.com'

export const Telos: Chain = {
  chainId: 40,
  chainName: 'Telos',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x61613A88019A7De363dC8E9182E408B3CC84DDd9',
  rpcUrl: 'https://mainnet.telos.net/evm',
  nativeCurrency: {
    name: 'TLOS',
    symbol: 'TLOS',
    decimals: 18,
  },
  blockExplorerUrl: telosExplorerUrl,
  getExplorerAddressLink: (address: string) => `${telosExplorerUrl}/address/${address}`,
  getExplorerTransactionLink: (tx: string) => `${telosExplorerUrl}/tx/${tx}`,
}

export default {
  Telos
}
