import { GET_CROSS_CHAIN_LIST } from "../config/queries";
import { useLazyQuery } from "@apollo/client";

export default function useCrossChainList() {
  const [_getCrossChainList, { loading, error, data }] = useLazyQuery(
    GET_CROSS_CHAIN_LIST,
    {
      fetchPolicy: "cache-first",
    },
  );

  const getCrossChainList = async () => {
    const crossChainList = await _getCrossChainList();
    return crossChainList?.data?.getCrossChainList?.chains || [];
  };

  return { getCrossChainList, loading, error, data };
}
