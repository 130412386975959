import React from "react";

export default function Badge({
  bgColor = "bg-secondary-400",
  textColor = "text-gray-900",
  className = "",
  children = <></>,
  ...rest
}) {
  return (
    <div
      className={`${bgColor} ${textColor} font-[700] py-0.5 px-2 rounded-full text-body5 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}
