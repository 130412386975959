import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Button({
  variant = "primary",
  disabled = false,
  loading = false,
  className = "",
  onClick = (...args: any): any => undefined,
  icon = undefined,
  size = "md",
  children,
  active = false,
  ...rest
}) {
  const IconComponent: any = icon || <></>;

  const btnSize = () => {
    switch (size) {
      case "xs":
        return `px-2 py-1 text-body5`;
      case "sm":
        return `px-4 py-1.5 text-body3`;
      case "md":
        return `px-4 py-3 text-body3`;
      case "lg":
        return `px-4 py-2.5 text-body2`;
      case "xl":
        return `px-4 py-3 text-body1`;
    }
  };

  const btnColor = () => {
    switch (variant) {
      case "gradient":
        return gradientColor();
      case "primary":
        return primaryColor();
      case "secondary":
        return secondaryColor();
      case "tertiary":
        return tertiaryColor();
      case "dark":
        return darkColor();
      case "light":
        return lightColor();
      case "link":
        return linkColor();
      case "plain":
        return plainColor();
      default:
        return primaryColor();
    }
  };

  const gradientColor = () => {
    return `
      text-white rounded-lg 
      ${!disabled && `bg-gradient-primary hover:brightness-[0.8]`}
      ${!!disabled && `!bg-grey-100 !text-grey-400`}
    `;
  };

  const primaryColor = () => {
    return `
      text-white rounded-lg bg-primary-400
      ${!disabled && !loading && `hover:bg-primary-800`}
      ${!!disabled && `!bg-grey-100 !text-grey-400`}
      ${active && `!bg-primary-800`}
    `;
  };

  const secondaryColor = () => {
    return `
      text-grey-400 rounded-lg bg-secondary-400 
      ${!disabled && !loading && `hover:bg-secondary-800`}
      ${!!disabled && `!bg-grey-100 !text-grey-400`}
    `;
  };

  const tertiaryColor = () => {
    return `
      text-white rounded-lg bg-grey-600 
      ${!disabled && !loading && `hover:bg-grey-400`}
      ${!!disabled && `!bg-grey-100 !text-grey-400`}
    `;
  };

  const lightColor = () => {
    return `
      text-white rounded-lg bg-grey-700 
      ${!disabled && !loading && `hover:bg-grey-400`}
      ${!!disabled && `!bg-grey-100 !text-grey-400`}
    `;
  };

  const darkColor = () => {
    return `
      text-white rounded-lg bg-grey-900 
      ${!disabled && !loading && `hover:brightness-[1.1]`}
      ${!!disabled && `!bg-grey-100 !text-grey-400`}
    `;
  };

  const linkColor = () => {
    return `
      text-primary-400 border-b border-primary-400 bg-transparent !p-0
      ${!disabled && !loading && `hover:text-primary-800 hover:border-primary-800`}
      ${!!disabled && `!text-grey-100 !border-grey-100`}
    `;
  };

  const plainColor = () => {
    return `
      text-primary-400 bg-transparent !p-0
      ${!disabled && !loading && `hover:text-primary-800`}
      ${!!disabled && `!text-grey-100`}
    `;
  };

  const handleOnClick = (e) => {
    if (disabled || loading || !onClick) return;
    onClick(e);
  };

  return (
    <button
      className={`
        flex items-center justify-center gap-2 transition-all 
        ${loading && `!brightness-[0.7] cursor-not-allowed`}
        ${disabled && `cursor-not-allowed`} 
        ${btnColor()} 
        ${btnSize()}
        ${className}`}
      disabled={disabled}
      onClick={handleOnClick}
      {...rest}
    >
      {loading ? (
        <StaticImage
          src="../../images/icons/spinner.png"
          alt=""
          height={15}
          className={`animate-spin ${(variant === `gradient` || variant === `primary`) && `invert`}`}
        />
      ) : !!IconComponent ? (
        icon
      ) : (
        <></>
      )}
      {children}
    </button>
  );
}
