import React, { useContext, useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { getPercentile } from "../utils/percentileUtils";
import { ChainContext } from "../contexts/ChainContext";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { getProvider } from "../utils/getProvider";

export interface IGasPriceOption {
  id: string;
  label: string;
  gwei: undefined | number;
  percentile: number;
  multiplier: number;
}

export default function useChainGasPrice() {

  const { selectedChainId } = useContext(ChainContext);
  const [ gasPriceOptions, setGasPriceOptions ] = useState<IGasPriceOption[]>([
    { id: "slow", label: "Slow", gwei: undefined, percentile: 40, multiplier: 1 },
    { id: "normal", label: "Normal", gwei: undefined, percentile: 70, multiplier: 1.1 },
    { id: "fast", label: "Fast", gwei: undefined, percentile: 90, multiplier: 1.25 },
  ]);

  const gasPriceCache = useRef({});

  const updateGasPriceOptions = async () => {
    const chainId = selectedChainId;

    if (gasPriceCache.current[chainId]) {
      setGasPriceOptions(gasPriceCache.current[chainId]);
      return;
    }

    const provider = getProvider(chainId);
    const blockNumber = await provider.getBlockNumber();
    const transactions = [
      ...((await provider.getBlockWithTransactions(blockNumber - 1))?.transactions || []),
      ...((await provider.getBlockWithTransactions(blockNumber - 2))?.transactions || []),
    ];
    const gasPrices = transactions.map((e) =>
      parseFloat(ethers.utils.formatUnits(e.gasPrice as any, "gwei"))
    );
    const newGasOptions = gasPriceOptions.map((e) => ({
      ...e,
      gwei: parseInt(getPercentile(e.percentile, gasPrices) * e.multiplier as any) as any,
    }));

    gasPriceCache.current[chainId] = newGasOptions;

    setGasPriceOptions(newGasOptions);
  };

  useEffect(() => {
    updateGasPriceOptions();
  }, [selectedChainId]);

  return { gasPriceOptions };
}