import React, { FC, useContext, useEffect, useState } from "react";
import Toastr from "../components/shared/Toastr";
import { ChainContext } from "./ChainContext";
import { expiryTimeOptions } from "../components/limit/LimitOrderExpiryTime";
import { ChainConfigContext } from "./ChainConfigContext";
import { DEFAULT_GAS_PRICE, DEFAULT_GAS_PRICE_ID, DEFAULT_SLIPPAGE } from "../config/constants";

export interface ISwapForm {
  crossChain: boolean;
  chainIdFrom: number;
  chainIdTo: number;
  tokenAddressFrom: string;
  tokenAddressTo: string;
  amountFrom: number | undefined;
  amountTo: number | undefined;
  slippage: number;
  gasPriceId: string;
  gasPrice: number;
  crossChainSelectedRoute: number;
  limitPrice: number | undefined;
  limitExpiryTimeMiliseconds: number | undefined;
  type: string | undefined; // limit, swap

  quoteData?: any;
  quoteCrossChainData?: any;
  txEstimatedCost?: number;
}

export interface IFormContext {
  form: ISwapForm,
  onFormChange: (obj:any) => any
}

const FormContext = React.createContext<IFormContext>(
  {} as IFormContext,
);

const FormProvider = ({ children }) => {

  const { selectedChainId } = useContext(ChainContext);
  const { getTokensByChainId, gasPriceOptions } = useContext(ChainConfigContext);
  const [form, setForm] = useState<ISwapForm>({
    crossChain: false,
    chainIdFrom: selectedChainId,
    tokenAddressFrom: "",
    amountFrom: 1,
    chainIdTo: selectedChainId,
    tokenAddressTo: "",
    amountTo: undefined,
    slippage: DEFAULT_SLIPPAGE,
    gasPriceId: DEFAULT_GAS_PRICE_ID,
    gasPrice: DEFAULT_GAS_PRICE,
    crossChainSelectedRoute: 0,
    limitPrice: undefined,
    limitExpiryTimeMiliseconds: expiryTimeOptions[1].miliseconds,
    type: "swap",
  });

  const onFormChange = (obj = {}) => {
    setForm((prev) => ({ ...prev, ...obj }));
  };

  useEffect(() => {
    [...new Set([form.chainIdFrom, form.chainIdTo])].map((chainId) => getTokensByChainId(chainId));
  }, [form.chainIdFrom, form.chainIdTo]);

  useEffect(() => {
    const _gasPrice = gasPriceOptions?.find(e => e.id === form.gasPriceId)?.gwei;
    if (_gasPrice) {
      onFormChange({ gasPrice: _gasPrice });
    }
    if (typeof window !== `undefined`) {
      localStorage.setItem(`gasPriceId`, form.gasPriceId);
    }
  }, [gasPriceOptions, form.gasPriceId]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (localStorage.getItem(`gasPriceId`)) {
        onFormChange({ gasPriceId: localStorage.getItem(`gasPriceId`) });
      }
    }
  }, []);

  return (
    <FormContext.Provider
      value={{ 
        form, 
        onFormChange 
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
