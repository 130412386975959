import { useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { getProvider } from '../utils/getProvider';

export default function useContract(contractAddress = "", abi = {}, chainIdReadOnly?: number) {
  const { walletProvider } = useWeb3ModalProvider();
  const [contract, setContract] = useState<ethers.Contract | undefined>();

  useEffect(() => {
    const _getContract = async () => {
      let provider;
      
      if (chainIdReadOnly) {
        provider = getProvider(chainIdReadOnly);
      } else if (walletProvider) {
        provider = new ethers.providers.Web3Provider(walletProvider);
        provider = await provider.getSigner();
      } else {
        setContract(undefined);
        return;
      }

      try {
        const contract = new ethers.Contract(contractAddress, abi as any, provider);
        setContract(contract);
      } catch (error) {
        console.error("Error creating contract:", error);
        setContract(undefined);
      }
    };

    _getContract();
  }, [contractAddress, abi, chainIdReadOnly, walletProvider]);

  return contract;
}