import { TEST_FORKED_CHAIN_ID, TEST_CHAIN_ID } from "../config/constants";
import { GET_TOKEN_LIST } from "../config/queries";
import { useLazyQuery } from "@apollo/client";

export default function useTokenQuery() {
  const [_getTokenList, { loading, error, data }] = useLazyQuery(
    GET_TOKEN_LIST,
    {
      fetchPolicy: "cache-first",
    },
  );

  const getTokenList = async (chainId: number) => {
    const tokenList = await _getTokenList({
      variables: {
        chainId: chainId === TEST_CHAIN_ID
          ? TEST_FORKED_CHAIN_ID
          : chainId,
      },
    });

    return tokenList?.data?.getTokenList || [];
  };

  return { getTokenList, loading, error, data };
}
