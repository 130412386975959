import React, { useContext } from "react";
import Badge from "../shared/Badge";
import { Trans } from "react-i18next";
import { FormContext } from "../../contexts/FormContext";

export const expiryTimeOptions = [
  {
    miliseconds: 1 * 24 * 3600 * 1000,
    label: "1 day",
    i18nKey: `limitOrderExpiryTime.days1`,
  },
  {
    miliseconds: 7 * 24 * 3600 * 1000,
    label: "1 week",
    i18nKey: `limitOrderExpiryTime.days7`,
  },
  {
    miliseconds: 30 * 24 * 3600 * 1000,
    label: "1 month",
    i18nKey: `limitOrderExpiryTime.days30`,
  },
  {
    miliseconds: 365 * 24 * 3600 * 1000,
    label: "1 year",
    i18nKey: `limitOrderExpiryTime.days365`,
  },
];

export default function LimitOrderExpiryTime() {

  const { form, onFormChange } = useContext(FormContext);

  return (
    <div className="flex justify-between items-center">
      <div className="text-grey-100 text-body4">
        <Trans i18nKey={`limitOrderExpiryTime.expiry`}>Expiry</Trans>
      </div>
      <div className="flex flex-wrap gap-2 pr-[1px]">
        {expiryTimeOptions.map((obj) => (
          <Badge
            bgColor={
              form.limitExpiryTimeMiliseconds == obj.miliseconds
                ? undefined
                : "bg-transparent outline outline-[1px] outline-secondary-400 font-normal text-secondary-400"
            }
            className="cursor-pointer"
            onClick={() =>
              onFormChange({ limitExpiryTimeMiliseconds: obj.miliseconds })
            }
          >
            <Trans i18nKey={obj.i18nKey}>{obj.label}</Trans>
          </Badge>
        ))}
      </div>
    </div>
  );
}
