import React from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import RootLayout from "../components/RootLayout";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <RootLayout>{element}</RootLayout>
  </ApolloProvider>
);
